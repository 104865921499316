import { DvToolbarEnvironment } from '@dv/toolbar-msal';

export const environment: DvToolbarEnvironment = {
  appKey: '2fb2995b-4e1c-48bf-8369-0877beeb0169',
  appName: 'CropMAP',
  defaultLanguage: 'sv',
  dvApiUrl:
    'https://app-datavaxtapi-staging-neu-001-dv.azurewebsites.net/api/v1/',
  iframeProxyUrl: 'https://toolbar.datavaxt.se',
  production: true,
  redirectUri: 'https://staging-cropmap.datavaxt.se',
  staging: true,
 
  baseApiUrl:
    'https://app-datavaxtapi-staging-neu-001-dv.azurewebsites.net/api/v1/',
  machineProviderUrl: 'https://machineprovider.datavaxt.se/',
  messagesUrl: 'https://stagingmessages.azurewebsites.net/',
};
